import SiteLogo from 'assets/images/autoraha-logo.svg';
import opLogo from 'assets/images/op-logo.svg';
import lahitapiolaLogo from 'assets/images/lahitapiola-logo.svg';
import sveaLogo from 'assets/images/svea-logo.svg';
import useFormCtx from 'hooks/useFormCtx'

import Link from 'components/global/buttons/Link'
import ButtonLink from 'components/global/buttons/ButtonLink'

export default function FrontPage() {
  const { formCtxDispatch } = useFormCtx()

  function setFormType(value) {
    formCtxDispatch({
      type: 'FORM_TYPE',
      payload: {
        formType: value
      }
    })
  }

  return (
    <div className="frontpage-bg">
      <div className="frontpage-wrapper">
        <div className='layout-vertical'>
          <img src={SiteLogo} alt="Autorahat" />

          <div>
            <p>Autoraha rahoittaa yksityisten ja yritysten välistä autokauppaa, poislukien autoliikkeiden autot. Luottamuksella jo vuodesta 2014.</p>

            <p>Voit hakea rahoitusta alla olevista linkeistä, joista siirrytään vahvaan tunnistautumiseen.
              Käytämme tietojesi käsittelyssä vahvaa tunnistautumista.</p>
          </div>
        </div>


        <div className='layout-vertical'>
          <Link href={'https://tunnistus.telia.fi/uas/oauth2/authorization?response_type=code&scope=openid&client_id=757f4d22-0cc1-4ee3-837b-168b9edea051&scope=openid&redirect_uri=https://rahoitushakemus.autoraha.fi/hakemus'}>
            Hae alustava luottopäätös yksityiselle
          </Link>

          {/* This is for local testing:
           <ButtonLink to={'/hakemus'}>
            Hae alustava luottopäätös yksityiselle
          </ButtonLink> */}

          <ButtonLink onClick={() => setFormType('company')} to={'/lomake'}>
            Hae alustava luottopäätös yritykselle
          </ButtonLink>
        </div>

        <div className="partner-logos text-center bg-white">
          <h2 className="heading">Yhteistyössä</h2>

          <div className='layout-horizontal'>
            <img src={opLogo} alt="OP" />
            <img src={lahitapiolaLogo} alt="LähiTapiola" />
            <img src={sveaLogo} alt="SVEA" />
          </div>
        </div>
      </div>
    </div>
  )
}