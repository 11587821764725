import { useDocumentTitle } from 'hooks/useDocumentTitle'

import SiteLogo from 'assets/images/autoraha-logo.svg';

export default function PageHeader({ title }) {
  useDocumentTitle(title)

  return (
    <>
      <div className="page-logo">
        <a href="/">
          <img src={SiteLogo} alt="Autorahat" />
        </a>
      </div>
      <div className="page-header">
        <h1 className="page-header__title">{title}</h1>
      </div>
    </>
  )
}